import React from "react";
import { graphql } from "gatsby";
import { I18nConsumer } from "@lib/i18n/i18n-react";
import Layout from "@components/_layout";
import { IconWithInfo } from "@components/activity-quick-info";
import ContactForm from "@components/contact-form";
import NoSpamEmailAddress from "@components/no-spam-email-address";
import phoneIcon from "@assets/feathericon/phone.svg";
import mailIcon from "@assets/feathericon/mail.svg";
import whatsappIcon from "@assets/icons/whatsapp.svg";
import signalIcon from "@assets/icons/signal.svg";
// import facebookIcon from "@assets/feathericon/facebook.svg";
// import instagramIcon from "@assets/feathericon/instagram.svg";
// import telegramIcon from "@assets/icons/telegram.svg";
import "./contact.scss";

export default function ContactLayout({
  data: { mdx: { frontmatter: { title, whatsapp, signal, phone }, fields: { obfuscatedEmail } } },
  pageContext
}) {
  return (
    <Layout title={title} {...pageContext}>
      <I18nConsumer scope="contact">
        {({ t }) =>
          <main id="main" className="ContactPage">
            <h1 className="ContactPage__heading">{t(".heading")}</h1>

            <div className="ContactPage__content" >
              <section className="ContactPage__contactInfo" aria-label={t(".contactInfoLabel")}>
                <IconWithInfo
                  title={t(".phone")}
                  content={<a href={`tel:${phone}`} target="_blank" rel="noreferrer">{phone}</a>}
                  iconSrc={phoneIcon} />

                <IconWithInfo
                  title={t(".email")}
                  content={<NoSpamEmailAddress encodedEmail={obfuscatedEmail} />}
                  iconSrc={mailIcon} />

                {/* <IconWithInfo
                  title={t(".instagram")}
                  content={<a href={`https://instagram.com/${instagram}`} target="_blank" rel="noreferrer">instagram.com/{instagram}</a>}
                  iconSrc={instagramIcon} /> */}

                {/* <IconWithInfo
                  title={t(".facebook")}
                  content={<a href={`https://fb.me/${facebook}`} target="_blank" rel="noreferrer">fb.me/{facebook}</a>}
                  iconSrc={facebookIcon} /> */}

                <IconWithInfo
                  title={t(".whatsApp")}
                  content={<a href={`https://wa.me/${whatsapp}`} target="_blank" rel="noreferrer">{whatsapp}</a>}
                  iconSrc={whatsappIcon} />

                {/* <IconWithInfo
                  title={t(".telegram")}
                  content={<a href={`https://t.me/${telegram}`} target="_blank" rel="noreferrer">{telegram}</a>}
                  iconSrc={telegramIcon} /> */}

                <IconWithInfo
                  title={t(".signal")}
                  content={<a href={`https://signal.me/#p/${signal}`} target="_blank" rel="noreferrer">{signal}</a>}
                  iconSrc={signalIcon} />
              </section>

              <section className="ContactPage__contactForm" aria-label={t(".contactFormLabel")}>
                <ContactForm />
              </section>

            </div>
          </main>
        }
      </I18nConsumer>
    </Layout>
  )
};

export const pageQuery = graphql`
query Contact($id: String!) {
  mdx(id: { eq: $id }) {
      frontmatter {
        title,
        instagram,
        facebook,
        whatsapp,
        phone,
        telegram,
        signal,
      },
      fields {
        obfuscatedEmail
      }
    },
  }
`;