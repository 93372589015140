import React from "react";
import { useForm } from 'react-hook-form';
import { useI18n } from "@lib/i18n/i18n-react";
import createFormPersist from "@lib/react-hook-form-persist";
import { contactFormValidations as validations } from "@lib/validations/form-validations";
import { registerInputForForm } from "@lib/utils/form-utils";
import { InputGroup, Input, apiUrl } from "@components/forms";
import ValidatedForm, { FormState } from "@components/validated-form";
import "./index.scss";

export default function ContactForm() {
    const { t, language } = useI18n("contactForm");

    const stateLabels = {
        [FormState.INITIAL]: t(".labels.submit.initial"),
        [FormState.SUBMITTING]: t(".labels.submit.submitting"),
        [FormState.SUBMITTED]: t(".labels.submit.submitted"),
        [FormState.INVALID]: t(".labels.submit.invalid"),
        [FormState.ERROR]: t(".labels.submit.error"),
    }

    const { register, watch, setValue, handleSubmit, formState: {errors} } = useForm();

    const useFormPersist = createFormPersist("ContactForm", { exclude: ["privacy"] });
    const { clear: clearSavedForm } = useFormPersist({ watch, setValue });

    const registerInput = registerInputForForm("ContactForm", register, t, errors, validations);

    return (
        <div className="ContactForm">
            <div className="ContactForm__container">
                <ValidatedForm
                    onSuccess={clearSavedForm}
                    onSubmit={handleSubmit}
                    url={apiUrl("contact", language)}
                    redirectUrl="/success/contact"
                    stateLabels={stateLabels}
                    submitErrorMessage={(reason) => t(".submitErrorMessage", { reason })}
                >

                    <h2 className="ContactForm__heading">{t(".heading")}</h2>

                    <InputGroup.Horizontal>
                        <Input.Text {...registerInput("name")} required />
                        <Input.Email {...registerInput("email")} required />
                    </InputGroup.Horizontal>

                    <Input.Textarea {...registerInput("message")} required rows={8} />

                    <InputGroup>
                        <Input.Checkbox {...registerInput("privacy")} required />
                    </InputGroup>

                    <Input.Honeypot t={t} registerInput={registerInput} />
                </ValidatedForm>
            </div>
        </div>
    );
}