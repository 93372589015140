import React, { useState, useEffect } from "react";
import { deobfuscate } from "@lib/utils/obfuscate";

export default function NoSpamEmailAddress({ encodedEmail }) {
  const [isHidden, setIsHidden] = useState(true);

  const email = deobfuscate(encodedEmail);

  useEffect(() => setIsHidden(false), []); // Show email only on client side with javascript

  return (
    isHidden
      ? <span>{email.replace("@", " (a) ").replace(".", " (.) ")}</span>
      : <a href={`mailto:${email}`} target="_blank" rel="noreferrer">{email}</a>
  );
}
