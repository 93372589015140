exports.deobfuscate = function deobfuscate(string) {
  const reversed = string.split("").reverse().join("");
  const decodedUri = decodeURIComponent(reversed);
  return decodedUri;
};

exports.obfuscate = function obfuscate(string) {
  const uriComponent = encodeURIComponent(string);
  const reversed = uriComponent.split("").reverse().join("");
  return reversed;
};